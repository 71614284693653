* {
padding: 0;
margin: 0;
box-sizing: border-box;
-webkit-font-smoothing: antialiased;
}
body {
font-family: "thesansb5plain";
overflow-x: hidden;
}
p {
/* font-size: 16px; */
line-height: 24px;
}
p.og-book-title {
    /* height: auto !important; */
}
.row {
margin-left: 0;
margin-right: 0;
}
 .slick-arrow.slick-prev svg{
    display: none !important;

}
.padding-0 {
padding: 0;
}
.flex {
display: flex;
}
.items-center {
align-items: center;
}
.justify-between {
justify-content: space-between;
}
.justify-center {
justify-content: center;
}
:root {
--primary: #bba367;
--secondary: #231f20;
}
.container-1630 {
max-width: 1630px;
width: 100%;
margin: 0 auto;
}
.container-1420 {
max-width: 1420px;
width: 100%;
margin: 0 auto;
}

img {
vertical-align: middle;
max-width: 100%;
height: auto;
}
a:hover,
a:focus,
button:focus,
.form-control:focus {
text-decoration: none;
outline: 0;
box-shadow: none;
}
a:focus,
a:active,
a:visited,
a:hover {
text-decoration: none;
}
input:focus {
outline: none;
}
button:focus,
select:focus {
outline: none;
box-shadow: none;
}
ul li {
list-style: none;
}



.modal-content {
    max-width: 900px !important;
}

.modal-dialog {
    width: 100%;
    max-width: 900px;
    margin: auto;
}

h4.modal-title {
    width: 100%;
    margin: 0 auto;
    font-size: 40px;
    text-align: center;
}

.modal-desc {
    text-align: center;
}

.form-group {
    margin: 0 auto;
    margin-bottom: 1rem;
}

.form-group input#amount {
    margin: 0 auto;
    text-align: center;
    height: 80px;
}

.modal-header {
    border-bottom: 0px;
}

button.btn.us-active-btn {
    background:  rgb(228, 164, 25);
    width: 100%;
    margin-bottom: 10px;
    height: 70px;
    font-size: 20px;
    color: white;
}

button.btn.us-active-btn:hover {
    border: 2px solid rgb(228, 164, 25);
    background: white;
    color: black;
}

.form-check-inline {
    width: 100%;
    text-align: center;
}

label.form-check-label {
    width: 100%;
    font-size: 20px;
    text-align: center;
}

button.btn.us-active-btn.last-btn {
    width: 40%;
    margin: 0 auto;
    margin-top: 1rem;
}
.data-not{
    text-align: center;
}



.modal-desc p {
    font-size: 20px;
}

.form-group input#amount {
    position: relative;
}

.form-group input#amount::before {
    content: '$';
    /* font-size: 20px; */
    /* position: absolute; */
    /* top: 0px; */
    /* left: 0px; */
    /* color: red; */
    /* width: 20px; */
    /* height: 20px; */
}

.form-group {}

.form-group.us-form {
    position: relative;
}

.form-group.us-form span {
    position: absolute;
    top: 30%;
    left: 5%;
    font-size: 20px;
}
@media(max-width:991px){
    .modal-content {
    max-width: 90vw !important;
    margin: 0 auto;
}

h4.modal-title {
    font-size: 25px;
}

.modal-desc p {
    font-size: 15px;
}
.form-group input#amount {
    margin: 0 auto;
    text-align: center;
    height: 60px;
}
.form-group.us-form span {
    position: absolute;
    top: 20%;
    left: 5%;
    font-size: 20px;
}

.row.col-row {}

.row.col-row .col-lg-4.px-4 {
    padding: 0px !important;
}

button.btn.us-active-btn.last-btn {
    width: 100%;
}
}
.delete_button{
    cursor: pointer;
    background-color: rgba(255, 0, 0, 0.808);
    color:         #423f40c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:  3px;
    margin-top: 4px; 
    height: 30px;
    left: -53px;
    position: relative;
    width: 30px;
   /* font-size:      12px;
   padding-right:  35px;
   padding-top:    3px;
    padding-left:   35px;
    padding-bottom: 3px;*/
}
.delete_button:hover{
    background-color: rgb(255, 0, 0);
    color:         #000000;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius:  3px;
   margin-top: 4px; 
   height: 30px;
   left: -53px;
   position: relative;
   width: 30px;
   transition: 0.3s;
}
.main-div{
    position: fixed;
    border-radius: 6px;
    border: 1px solid #FFC240;
    bottom: 2px;
    z-index: 99;
    width: 95%;
    left:2%;
    height:60px; 
    background: linear-gradient( to bottom right, rgba(77, 74, 74, 1) -18%, rgba(62, 60, 60, 1) 5%, rgba(0, 0, 0, 1) 100% );
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

.inner-banner-div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px;
}
.inner-banner-div img{
    width: 46px;
    height: 46px;
    border-radius: 4px;
    
}
.inner-banner-div p{
    color: white;

}
.info-div{
    width: 70%;
    max-height: 40%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: white;
    line-height: normal;
    margin: 6px;
}
.info-div h1{
    font-size: 17px;
    margin: 0;
    padding: 0;
    font-weight: 900;

}
.info-div p{
    font-size: 11px;
    margin: 0;
    padding: 0;

}

  /* @media (max-width: 768px)  {
   .main-div{ position: fixed;
      margin-top: -80px;
  }
  } */


  



/* CSS */
.button-91 {
  color: #fff;
  /* padding: 4px 13px; */
  width: 140px;
  height: 40px;
  background-color: #181a1a;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;
}
.button-91 a{
    color: white;
}
.close-button{
    position:absolute; 
    top:-8px; 
    left:-5px; 
    border-radius:10px; 
    border:1px solid #FFC240; 
    color:#FFC240; 
    background:black
}


.main-div-close{
    display: none;
}