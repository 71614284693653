* {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
}

body {
  text-decoration: none;
  list-style: none;
  font-family: Montserrat, sans-serif;
}

.container-1400 {
  max-width: 1400px;
  margin: 0px auto;
}

.user-record img {
  width: 160px;
  height: 160px;
  border-radius: 50%;

  position: relative;
}

.header {
  background: rgb(243, 243, 243);
  padding: 5% 0 0 0;
}

.user-acc-circle-dropdown.dashboard-header {
  width: 120px;
  height: 120px;
}
.circle-box {
  width: 150px !important;
  height: 150px !important;
}

.prof-img {
  font-size: 60px;
}


.user-record .user-acc-circle-dropdown {
  margin-bottom: -1vw;
}

.user-record .email-record p {
  margin-top: 0;
  font-weight: 300;
}

.email-record h6 {
  margin-bottom: 2px;
}

.user-record h3 {
  line-height: 1em;
  margin-bottom: 0;
}

.cs-pro p {
  padding-top: 0;
}

span.span-star {
  background: black;
  color: white;
  text-align: center;
  padding: 10px 25px;
  border-radius: 20px;
  font-weight: 900;
}

.profile-page-box .fa-star::before {
  content: "";
  /* color: gold; */
  color: #fe8c2b;
}

.my_prof_div {
  margin-top: 7%;
}

.choose-image-div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  top: 110px;
  left: 125px;
  cursor: pointer;
  border: 1px solid black;
}
.choose-image-div i {
  font-size: 20px;
}
.cs-pro p {
  font-size: 18px;
  color: rgba(120, 120, 120, 0.65);
}

.user-edit {
  padding-left: 20px;
}

.user-record {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.user-record h3 {
  font-size: 30px;
  font-weight: 600;
}

.email-record h6 {
  font-size: 23px;
}

ul.navbar-nav.ml-auto.header-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ul.navbar-nav.ml-auto.header-bar li a {
  font-family: 'montserrat-semibold';
  font-size: 20px;
  margin-left: 70px;
  color: #454545;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
}

.hover-effect {
  position: relative;
}

a.nav-link.hover-effect:hover {
  background-color: unset !important;
  /* color: rgb(65, 155, 205) !important; */
  color: #454545 !important;
}

.hover-effect::after {
  width: 0px;
  height: 2px;
  content: "";
  position: absolute;
  background: goldenrod;
  color: white;
  left: 7px;
  bottom: 0px;
  transition: all 0.5s ease-in-out 0s;
}

.hover-effect:hover::after {
  width: 100%;
}

a.nav-link.hover-effect.active::after {
  width: 100%;
}


.change_pass_component.sec-3 {
  padding-top: 0px;
}
.row.profile-sec {
  padding-bottom: 20px;
}

span.input-font i {
  margin-top: 9px;
}

.profile input:focus {
  background: rgb(217, 215, 215);
}

.sec-4 h1,
.my_prof_div .sec-3 h1,
.sec-2 h1,
.sec-1 h1 {
  font-size: 45px;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 20px 0px;
  color: #454545;
}

h1.chngPassSetting,
h1.accFeatureSetting {
  margin-top: 40px;
}



.profile-edit {
  background: #f9f9f9;
  padding: 30px;
}

.change-password {
  background: rgb(243, 243, 243);
  padding: 30px;
}

.profile input {
  width: 100%;
  height: 45px;
  padding: 0px 15px;
  border: 1px solid rgba(187, 179, 179, 0.43);
  border-radius: 7px;
  outline: none;
}

.profile h6 {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.38);
  margin: 0px;
}

.profile input::placeholder {
  color: rgba(0, 0, 0, 0.76);
  font-weight: 500;
}

.saveBtnColAccFeature{
  padding: 30px;
}

.profile-button {
  text-align: end;
}

.profile-button button {
  width: 170px;
  height: 45px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 45px;
  border: none;
  color: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.82);
}

.input-icon {
  position: relative;
}

.input-icon span {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 25px;
  width: 53px;
  height: 45px;
  padding-top: 0px;
  text-align: center;
  background: white;
  border: 1px solid rgb(225, 222, 222);
  border-radius: 7px 0px 0px 7px;
}

.profile.input-icon input {
  padding-left: 65px;
}

.input-icon span i {
  color: rgb(151, 151, 151);
}

.accounts-features {
  background: rgb(243, 243, 243);
  padding: 40px 0 0px;
  border: 1px solid rgb(229, 228, 228);
}
.row.feature-border:last-child {
  border-bottom: 0;
}

/* .sec-2 h1 {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 35px;
  font-family: Montserrat;
  font-weight: 600;
} */

.feature h6 {
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.38);
}

.feature.settingfFeatures {
  text-align: left;
  padding-left: 30px;
}

.feature {
  position: relative;
  padding: 10px 0px;
}

span.lock i {
  position: absolute;
  right: 0px;
  font-size: 40px;
  color: rgb(151, 151, 151);
}

#check-1:checked ~ .lock {
  display: none;
}
.check-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
}

.check-1 .text {
  margin-left: 15px;
}

.check-lable input:checked ~ .lock {
  display: none;
}

.check-lable input {
  width: 20px;
  height: 20px;
  border: 1px solid rgb(207, 207, 207);
  cursor: pointer;
  visibility: hidden;
  display: flex;
}

.check-lable {
  display: flex;
  align-items: center;
  width: 100%;
}

.feature label {
  font-size: 17px;
  font-family: Montserrat;
  font-weight: 600;
  padding-left: 8px;
  margin-bottom: 0px;
}

.feature p {
  font-size: 14px;
  font-weight: 400;
}

.row.feature-border {
  border-bottom: 1px solid rgb(229, 228, 228);
  margin-bottom: 30px;
}

.col-lg-8.check-col {
  padding-bottom: 20px;
}

.col-lg-12.button-feature {
  padding: 40px 75px 0;
}

span.HEADING {
  font-size: 13px;
  color: rgba(118, 116, 116, 0.59);
  text-transform: capitalize;
}

.sec-4 {
  padding-bottom: 60px;
}

.subscription {
  background: rgb(243, 243, 243);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28.55px;
  padding-bottom: 0.05px;
}

.sec-3 h1 {
  padding-bottom: 30px;
  font-size: 35px;
  font-family: 'montserrat';
  color: #454545;
  font-weight: 600;
}

.sub-method h6 {
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.38);
}

.sub-method {
  padding: 20px 0px;
}

.sub-method.subscBtnMain {
  display: block;
  width: 100%;
  text-align: right;
}
.sub-method.subscBtnMain button{
  color: #9c9c9c;
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 800;
}

.sub-method.subscBtnMain button:hover{
  color: #ffc240;
}

.sub-method h5 {
  text-align: end;
  font-size: 18px;
  font-family: Montserrat;
  cursor: pointer;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.38);
}

.sub-method p {
  font-size: 17px;
  font-weight: 500;
}

.sub-method label {
  font-size: 17px;
  font-weight: 700;
}

.sub-method input {
  width: 18px;
  height: 18px;
  border-radius: 60px;
}

.sub-check {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.sub-check label {
  margin-bottom: 0px;
  padding: 0px 0px 0px 9px;
}

.sub-method span i {
  color: rgb(0, 0, 120);
  font-size: 35px;
  padding-right: 10px;
}

.sub-method span {
  font-size: 19px;
  display: flex;
  align-items: end;
}

.history table {
  width: 100%;
}

tr.head-edit th {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.38);
  padding: 30px 0px 30px 20px;
  background: rgb(243, 243, 243);
  text-align: center;
}

table.history-edit tr td {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  font-family: 'montserrat-semibold';
  color: #454545;
  border-bottom: 2px solid rgb(243, 243, 243);
  padding: 0px 0px 0px 20px;
  text-transform: uppercase;
}

tr {
  border: 1px solid rgb(243, 243, 243);
}


.profile.input-icon input:focus {
  background: rgb(225, 222, 222);
}
.col-lg-8.my_subs_div_1 {
  display: flex;
  align-items: center;
}

.col-lg-4.my_subs_div_2 {
  display: flex;
  align-items: center;
}
.my_chk {
  width: 60px;
  height: 30px;
  background: gray;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  transition: 0.5s ease all;
}

.my_chk span {
  position: absolute;
  width: 50%;
  height: 100%;
  background: white;
  border-radius: 50%;
  transition: 0.5s ease all;
  border: 2px solid gray;
  left: 0;
}

.check-lable input:checked ~ label .my_chk {
  background: #45d145;
}

.check-lable input:checked ~ label .my_chk span {
  left: 50%;
  border-color: #45d145;
}
.my_chk {
  width: 60px;
  height: 30px;
  background: gray;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  transition: 0.5s ease all;
}

.my_chk span {
  position: absolute;
  width: 50%;
  height: 100%;
  background: white;
  border-radius: 50%;
  transition: 0.5s ease all;
  border: 2px solid gray;
  left: 0;
}

.check-lable input:checked ~ label .my_chk {
  background: #45d145;
}

.check-lable input:checked ~ label .my_chk span {
  left: 50%;
  border-color: #45d145;
}

.feature .check-lable input {
  display: none;
}

.feature label {
  display: flex;
  align-items: center;
  padding: 0;
}

.feature label .my_chk + div {
  width: calc(100% - 65px);
  margin-left: 5px;
  font-size: 20px;
  line-height: 1em;
}

.feature h6 {
  font-size: 20px;
}

.feature {
  padding: 0;
}

.feature p {
  font-size: 16px;
}

.feature .enabletxt p:first-child{
  font-size: 28px !important ;
  margin: 10px 0px ;
}

.feature .enabletxt p:last-child{
  font-size: 20px !important ;
  margin-top: 0px !important;
}

.my_ac_feat_component .accounts-features {
  padding-left: 0;
  padding-right: 0;
}
.row.feature-border .col-md-3 {
    text-align: center;
}
table.history-edit tr td,
tr.head-edit th,
button.btn.invoicebtn{
  text-align: left;
}
table.history-edit tr td,
tr.head-edit th{
  padding-left: 40px;
}
.history-edit tr th.historyTab_Date{
  min-width: 100px;
}
.cancelSubscription_modal img{
  display: block;
  width: 100px;
  margin: 0 auto;
}
.cancelSubscription_modal p {
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Montserrat';
  margin: 50px 0px 90px;
}
.cancelSubscription_modal p span{
  font-weight: 800;
}
.cancelSubscription_modal .cancelSubsModal_btns {
  display: block;
  width: 110px;
  color: #fff;
  background-color: #232323;
  text-align: center;
  padding: 5px 8px;
  font-size: 18px;
  border: 2px solid #232323;
  border-radius: 30px;
}
.cancelSubscription_modal .cancelSubsModal_btns:last-child{
  color: #232323;
  background-color: #fff;
  margin-left: 20px;
}
.cancelSubscription_modal .modal-title {
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: 600;
  color: #232323;
}
.cancelSubscription_modal .modal-content{
  border-radius: 0px;
}
.cancelSubscription_modal .modal-dialog {
  max-width: 420px !important;
}
.cancelSubscription_modal .modal-header {
  padding-bottom: 0;
  padding-top: 25px;
}
.cancelSubscription_modal .modal-body{
  padding-bottom: 25px;
}
.cancelModalBtn_main{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sub-method h6{
  margin: 0;
}
.subscBtnMain .btn{
  vertical-align: unset;
}
.alignBaseline_responsive{
  display: flex;
  align-items: baseline !important;
}
.ongoingStyle{
  cursor: pointer;
}
.ongoingStyle:hover {
  color: black;
}

.cs-pro.membership  {
  display: flex;
  align-items: center;
  column-gap: 25px;
  margin: 20px 0;
  flex-direction: column;
  align-items: end;
}
.cs-pro.membership .user-membership {
  background: #333333;
  padding: 8px 35px;
  border-radius: 100px;
  color: #fff;
  font-family: 'Montserrat-Bold';
  text-transform: uppercase;
}

.cs-pro.membership .user-membership span:first-child {
  margin-right: 10px;
}

.free-pkg .freebook-subtitle {
  text-transform: uppercase;
  font-size: 18px;
  color: #948f8f;
  font-weight: 600;
  display: none;
}

.free-pkg  .book-ratings i.fa-star-half-stroke {
  color: #fe8c2b;
}
.free-pkg  .book-ratings span {
  margin-left: 10px;
  font-weight: 600;
  color: #948f8f;
}

.free-pkg .book-ratings {
  margin: 10px 0;
  display: none;
}
.free-pkg  .whishlist-btn {
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}





@media screen and (max-width: 1600px) {
  .user-record h3 {
    font-size: 28px;
  }

  .email-record h6 {
    font-size: 21px;
  }

  .user-record img {
    width: 150px;
    height: 150px;
  }

  .cs-pro p {
    font-size: 16px;
  }

  span.span-star {
    font-size: 15px;
  }

  ul.navbar-nav.ml-auto.header-bar li a {
    font-size: 18px;
  }

  .free-book-label {
    font-size: 42px;
  }

  .sec-3 h1 {
    font-size: 32px;
  }

  .sub-method.subscBtnMain button,
  .sub-method h6 {
    font-size: 16px;
  }

  button.btn.us-active-btn {
    font-size: 16px;
    height: 50px;
    width: 70%;
  }

  tr.head-edit th {
    font-size: 16px;
    /* text-align: center; */
  }

  table.history-edit tr td {
    font-size: 16px;
    /* text-align: center; */
  }

  .profile h6 {
    font-size: 16px;
  }

  .sec-2 h1 {
    font-size: 32px;
  }

  .feature h6 {
    font-size: 18px;
  }

  .my_chk {
    width: 50px;
    height: 25px;
  }

  .profile-button button {
    font-size: 16px;
  }
  
  .feature label .my_chk + div {
      font-size: 15px;
      width: calc(100% - 35px);
  }

  .feature .enabletxt p:first-child{
    font-size: 28px !important ;
    margin: 10px 0px;
  }

  .feature .enabletxt p:last-child{
    font-size: 24px !important ;
  }
  .feature .enabletxt p:first-child{
    font-size: 26px !important ;
    margin: 10px 0px;
    margin-top: 0px !important;
  }

  

}

@media screen and (max-width: 1440px) {
  .user-record h3 {
    font-size: 24px;
  }

  .user-acc-circle-dropdown.dashboard-header {
    width: 110px;
    height: 110px;
  }

  .dashboard-header .user-acc-label-dropdown {
    font-size: 58px;
  }

  .email-record h6 {
    font-size: 18px;
  }

  .email-record p {
    font-size: 14px;
  }

  .user-record img {
    width: 140px;
    height: 140px;
  }

  .cs-pro p {
    font-size: 14px;
  }

  span.span-star {
    font-size: 14px;
  }

  ul.navbar-nav.ml-auto.header-bar li a {
    font-size: 16px;
  }

  ul.navbar-nav.ml-auto.header-bar {
    justify-content: center;
  }

  .free-book-label {
    font-size: 38px;
  }

  .sub-method h6 {
    font-size: 14px;
  }

  .sub-method p {
    font-size: 14px;
    margin: 0 auto;
  }

  button.btn.us-active-btn {
    font-size: 14px;
    height: 46px;
    width: 68%;
  }

  .sub-method {
    padding: 10px 0;
  }

  .row.feature-border {
    margin-bottom: 20px;
  }

  .sec-4 h1,
  .my_prof_div .sec-3 h1,
  .sec-2 h1,
  .sec-1 h1 {
    font-size: 30px;
  }

  tr.head-edit th {
    font-size: 14px;
  }

  table.history-edit tr td {
    font-size: 14px;
  }

  .sec-1 h1 {
    font-size: 28px;
  }

  .profile h6 {
    font-size: 14px;
  }

  .profile input {
    font-size: 14px;
    height: 40px;
  }

  .input-icon span {
    height: 40px;
  }

  span.input-font i {
    font-size: 18px;
  }

  .sec-2 h1 {
    font-size: 28px;
  }

  .feature h6 {
    font-size: 16px;
  }

  .feature p {
    font-size: 13px;
  }

  .my_chk {
    width: 40px;
    height: 20px;
  }

  .profile-button button {
    font-size: 14px;
    height: 40px;
    width: 160px;
  }

  .feature .enabletxt p:first-child{
    font-size: 26px !important ;
    margin: 10px 0px;
  }

  .feature .enabletxt p:last-child{
    font-size: 22px !important ;
    margin-top: 0px !important;
  }
  .my_readbook_sec2 button.btn.us-active-btn{
    width: 30% !important;
    display: block;
    margin: 10px auto !important;
    max-width: 200px;
    padding: 8px;
    margin-top: 20px;
  }

}

@media screen and (max-width: 1279px) {
  .package-info-div img {
    min-height: auto;
    max-height: 170px;
    max-width: 49%;
    margin: 0 !important;
  }

  .user-acc-circle-dropdown.dashboard-header {
    width: 100px;
    height: 100px;
  }

  .dashboard-header .user-acc-label-dropdown {
    font-size: 52px;
    line-height: 1em;
  }

  .user-record h3 {
    font-size: 20px;
  }

  .email-record h6 {
    font-size: 16px;
  }

  .email-record p {
    font-size: 12px;
  }

  .user-record img {
    width: 120px;
    height: 120px;
  }

  .cs-pro p {
    font-size: 12px;
  }

  span.span-star {
    font-size: 12px;
  }

  ul.navbar-nav.ml-auto.header-bar li a {
    font-size: 14px;
    margin: 0 auto;
  }

  .free-book-label {
    font-size: 34px;
  }

  

  .sec-3 h1 {
    font-size: 26px;
  }

  .sub-method.subscBtnMain button,
  .sub-method h6 {
    font-size: 12px;
  }

  .sub-method p {
    font-size: 12px;
    line-height: 16px;
  }

  button.btn.us-active-btn {
    width: 100%;
    font-size: 12px;
    height: auto;
  }

  .sec-4 h1,
  .my_prof_div .sec-3 h1,
  .sec-2 h1,
  .sec-1 h1 {
    font-size: 26px;
  }

  tr.head-edit th {
    font-size: 12px;
  }

  table.history-edit tr td {
    font-size: 12px;
  }

  .profile h6 {
    font-size: 12px;
  }

  .profile input {
    font-size: 12px;
    height: 35px;
  }

  .input-icon span {
    height: 35px;
    font-size: 20px;
  }

  span.input-font i {
    font-size: 14px;
    margin-top: 0px;
  }

  .sec-2 h1 {
    font-size: 26px;
  }

  .sec-1 h1 {
    font-size: 26px;
  }

  .feature h6 {
    font-size: 14px;
  }

  .feature label .my_chk + div {
    font-size: 14px;
  }

  .feature p {
    font-size: 11px;
    line-height: 1.6em;
  }

  .my_chk {
    width: 30px;
    height: 15px;
  }

  .profile-button button {
    font-size: 12px;
    width: 130px;
    height: 35px;
  }

  .feature .enabletxt p:first-child{
    font-size: 23px !important ;
    margin: 10px 0px ;
  }

  .feature .enabletxt p:last-child{
    font-size: 18px !important ;
    margin-top: 0px !important;
  }
  .package-info-div.profile a {
    min-height: unset;
    margin: 0;
    width: 48%;
  }

  .package-info-div a img {
      max-width: 100%;
      max-height: unset;
      height: 100%;
      object-fit: cover;
  }



}

@media (max-width: 1167px){
  .alignBaseline_responsive{
    display: flex;
    align-items: baseline;
  }
}


@media screen and (max-width: 980px) {

  .package-info-div img {
    max-height: unset;
  }

  ul.navbar-nav.ml-auto.header-bar {
    margin-bottom: 40px;
  }

  .profile-sec .col-lg-4 {
    width: 50%;
  }

  .sec-1 {
    padding-top: 30px;
  }
  .cs-pro p {
    padding: 0;
  }
  .feature .enabletxt p:first-child{
    font-size: 20px !important ;
    margin: 10px 0px ;
  }

  .feature .enabletxt p:last-child{
    font-size: 16px !important ;
    margin-top: 0px !important;
  }

  button.btn.us-active-btn {
    width: 40%;
    font-size: 12px;
    height: auto;
  }
  .cancelSubscription_modal.modal{
    margin-top: 20%;
  }
  .cancelSubscription_modal p{
    font-size: 16px;
    margin: 30px 0px 50px;
  }
  .cancelSubscription_modal img{
    width: 85px;
  }
  .cancelSubscription_modal .cancelSubsModal_btns{
    width: 100px;
    font-size: 16px;
  }

}

@media (max-width: 767px) {
  /* .my_prof_div .free-book-section,
  .my_prof_div .package-info-div {
    display: none;
  } */

  /*  */
  .user-acc-circle-dropdown{
    border: none;
  }
  .user-acc-circle-dropdown.dashboard-header {
    width: 120px !important;
    height: 120px  !important;
}
  /*  */
  .header.my_profile_header .container .row {
    flex-flow: wrap;
    justify-content: center;
  }

  .header.my_profile_header .container .row .col-lg-8 {
    width: 100%;
    padding: 0;
  }

  .header.my_profile_header
    .container
    .row
    .col-lg-4.mt-auto.text-right
    .cs-pro {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header.my_profile_header
    .container
    .row
    .col-lg-4.mt-auto.text-right
    .cs-pro
    span.span-star {
    display: block;
    height: fit-content;
    width: fit-content;
    font-size: 12px;
    padding: 5px 15px;
  }

  .header.my_profile_header
    .container
    .row
    .col-lg-4.mt-auto.text-right
    .cs-pro
    p {
    font-size: 13px;
    padding: 0;
  }

  .header.my_profile_header .user-record {
    width: 100%;
    align-items: center;
    flex-flow: wrap;
    flex-direction: row;
    text-align: center;
    gap: 20px;
  }
  .user-acc-circle-dropdown.dashboard-header {
    width: 80px;
    height: 80px;
}
.dashboard-header .user-acc-label-dropdown {
  font-size: 42px;
}
.cs-pro {
  text-align: center;
  margin-top: 20px;
}

  .header.my_profile_header .user-record img {
    width: 35%;
    height: auto;
    position: unset;
    display: block;
  }

  .header.my_profile_header .user-record .user-edit {
    padding: 0;
    width: 60%;
    text-align: left;
  }

  .header.my_profile_header .user-record .user-edit h3 {
    font-size: 20px;
    line-height: 1em;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .header.my_profile_header .user-record .user-edit .email-record h6 {
    font-size: 14px;
    margin-bottom: 0;
    margin: 6px 0;
  }

  .header.my_profile_header .user-record .user-edit .email-record p {
    margin: 0;
    line-height: 1.4em;
    font-size: 12px;
    font-weight: 600;
    color: #acabb7;
  }

  .header.my_profile_header .user-record .user-edit span {
    text-transform: capitalize;
    display: block;
  }
  ul.navbar-nav.ml-auto.header-bar.my_prof_nav {
    justify-content: space-between;
    flex-flow: unset;
    padding: 10px 0;
  }

  ul.navbar-nav.ml-auto.header-bar.my_prof_nav li.nav-item {
    display: block;
  }

  ul.navbar-nav.ml-auto.header-bar.my_prof_nav li.nav-item a {
    margin: 0;
    padding: 0;
    font-size: 13px;
    text-align: center;
    position: relative;
  }

  ul.navbar-nav.ml-auto.header-bar.my_prof_nav li.nav-item a::after {
    height: 2px;
    left: 0;
    bottom: -3px;
  }
  .my_prof_div .row.row-425.spacing-adjust {
    flex-flow: wrap;
    justify-content: flex-start;
    gap: 0;
  }

  
  .my_prof_div .row.row-425.spacing-adjust .og-books {
    width: 47.333%;
    max-width: 90px !important;
    margin-right: 10px;
    padding: 0;
  }
  .favBooksQa.section-div.most_popular .row.center-most-popular-in-mobile.spacing-adjust .most-popular-books{
    /* max-width: 90px !important;
    margin-right: 10px; */
    max-width: 70px !important;
    margin-right: 10px;
  }

  .my_prof_div .row.row-425.spacing-adjust p.og-book-title {
    display: block;
    font-size: 11px;
    line-height: 1.3em;
  }

  .my_prof_div .row.row-425.spacing-adjust p.og-book-title,
  p.mp-book-title.mostPopBookTitle{
    height: 40px;
    padding-bottom: 5px;
    -webkit-line-clamp: 2;
    font-size: 14px;
  }
  .my_prof_div .row.row-425.spacing-adjust p.og-book-title{
    height: 35px;
  }
  .my_prof_div .row.row-425.spacing-adjust .mp-book-chapters svg {
    display: none;
  }

  .my_prof_div
    .row.row-425.spacing-adjust
    .mp-book-chapters
    p.mp-book-chapter-number {
      background: goldenrod;
      font-size: 10px;
      font-weight: 700;
      color: black;
      border-radius: 50px;
      padding: 4px 20px;
      margin-top: 10px;
      line-height: 1.1em;
  }
  .bookmarks-chapters-div p {
    background: goldenrod;
    font-size: 10px;
    font-weight: 700;
    color: black;
    border-radius: 50px;
    padding: 5px;
  }
  .bookmarks-chapters-div {
    border-radius: 25px;
    height: 30px;
    justify-content: center;
    font-weight: 700;
    display: flex;
    margin: 0;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    align-items: center;
    width: fit-content;
    background-color: transparent;
  }
  .my_prof_div .sec-3 {
    padding: 0;
  }
  h1.historyHeading_bill,
  .my_prof_div .sec-3 h1 {
    font-size: 22px;
    padding: 15px 0;
    text-align: center;
  }
  h1.historyHeading_bill{
    margin-top: 20px;
  }
  .my_subs_div_1 .col-lg-8 ~ .col-lg-4 {
    background-color: black !important;
  }

  .col-lg-8.my_subs_div_1 {
    display: block;
    padding: 0;
    width: 70%;
  }

  .col-lg-4.my_subs_div_2 {
    width: 60%;
    padding: 4px;
}

  .col-lg-8.my_subs_div_1 .col-lg-4 {
    width: 100%;
    padding: 0 0 10px 0;
  }

  .col-lg-8.my_subs_div_1 .col-lg-8 {
    width: 100%;
    padding: 0;
  }

  .col-lg-8.my_subs_div_1 .col-lg-4 .sub-method h6 {
    font-size: 14px;
    line-height: 1em;
    margin: 0;
  }

  .col-lg-8.my_subs_div_1 .col-lg-8 .sub-method p {
    font-size: 13px;
    line-height: 1.2em;
    margin: 0;
  }

  .feature-border .sub-method {
    padding: 0;
  }

  .my_prof_div .subscription {
    padding: 10px;
  }

  .my_subs_div_2 .col-lg-12 {
    padding: 0;
  }

  .my_subs_div_2 .col-lg-12 .sub-method h5 {
    margin: 0;
    font-size: 12px;
  }

  .my_prof_div .row.feature-border {
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .feature-border .sub-method .sub-check label {
    font-size: 12px;
  }

  .feature-border .sub-method .sub-check {
    margin-top: 10px;
  }

  .feature-border .sub-method span {
    font-size: 12px;
    align-items: center;
    font-weight: 700;
    text-transform: capitalize;
  }

  table.history-edit tr td {
    padding: 5px 5px 5px 10px;
    line-height: 1.2em;
  }
  .history-edit tr th{
    font-size: 14px;
  }
  table.history-edit tr td{
    font-size: 13px;
  }
  .my_edit_component.sec-1 {
    padding: 0;
  }

  .my_edit_component.sec-1 h1,
  .my_ac_feat_component.sec-2 h1 {
    font-size: 22px;
    padding: 15px 0;
    text-align: center;
  }

  .my_edit_component.sec-1 .profile-edit {
    padding: 20px 0;
  }

  .my_edit_component.sec-1 .profile-edit .col-lg-4 {
    margin-bottom: 10px;
  }

  .my_edit_component.sec-1 .profile-edit input {
    width: 100%;
    font-size: 14px;
    padding: 10px 0px 10px 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .change_pass_component.sec-3 {
    padding: 0;
  }

  .change_pass_component.sec-3 h1,
  .change_pass_component.sec-3 .change-password {
    padding: 20px 0;
  }

  .change_pass_component.sec-3 .change-password .col-lg-4 {
    margin-bottom: 10px;
  }

  .change_pass_component.sec-3 .change-password input {
    width: 100%;
    font-size: 14px;
    padding: 10px 0px 10px 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .profile.input-icon {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .input-icon span {
    position: relative;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  span.input-font i {
    margin: 0;
  }

  .profile-button {
    text-align: center;
  }

  .profile-button button {
    height: auto;
    font-size: 16px;
    width: auto;
    padding: 10px 20px;
  }
  .my_ac_feat_component.sec-2 {
    margin-top: 20px;
  }
  .col-lg-12.button-feature {
    padding: 0;
    padding-top: 20px;
  }
  .feature label .my_chk + div {
    font-size: 14px;
  }

  .feature h6 {
    font-size: 14px;
  }

  .feature p {
    font-size: 12px;
  }

  .profile-sec .col-lg-4 {
    width: 100%;
  }
  .feature .enabletxt p:first-child{
    font-size: 16px !important ;
    margin: 10px 0px ;
  }
  
  .feature .enabletxt p:last-child{
    font-size: 14px !important ;
    margin-top: 0px !important;
  }
  button.btn.us-active-btn {
    width: 80%;
    font-size: 12px;
    height: auto;
  }
  .history {
    display: block;
    width: 100%;
    overflow: scroll;
  }
  .profHeadUserDet.header{
    padding-top: 130px;
  }
  .sub-method.subscBtnMain button, .sub-method h6{
    padding: 0;
  }
  table.history-edit tr.head-edit th {
    padding-left: 10px;
  }
  .invoicebtn{
    height: 50px;
    font-size: 16px;
  }
  .accounts-features .settingfFeatures{
    margin: 10px 0px 20px;
  }
  .accounts-features{
    padding: 0px;
  }
  .cancelSubscription_modal.modal {
    margin-top: 20%;
  }
  .cs-pro.membership{
    column-gap:20px;
    align-items:center;
    flex-direction:row;
  }
  .cs-pro.membership .user-membership {
    padding: 6px 20px;
  }
  .cs-pro.membership p {
    font-size: 16px;
  }
  .free-pkg .freebook-subtitle {
    font-size: 15px;
    display: block;
    padding-bottom: 0 !important;
    margin: 0 !important;
    line-height: 10px;
}
.free-pkg .book-ratings {
  display: block;
  font-size: 14px;
  margin-bottom: 0;
}
.free-pkg .read-p {
  background-color: #2641de;
  color: #fff !important;
  width: unset;
  height: unset;
  line-height: unset;
  padding: 8px 20px;
  margin-top: 0;
}
.free-pkg  .read-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.free-pkg .whishlist-btn {
  display: flex;
}
.my_prof_div .free-book-image {
  width: 100%;
  min-width: unset;
  max-width: 120px;
  height: 180px;
}

.free-book-rectangle .three-lines{
  display: none;
}
.og-books .og-image-and-text-container img {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}
.my_prof_div .row.row-425.spacing-adjust p.og-book-title {
  font-size: 12px;
}
.mp-image-and-text-container img {
  width: 100% !important;
  height: 100% !important;
}
.book-title.mostPopBookTitle {
  font-size: 12px;
}
.my_prof_div .grounded-radiants{
  border: 4px solid #97e05f;
}
.my_prof_div .grounded-radiants::after{
  display: none;
}

}


@media (max-width: 575px){
  .profPageHeadRightCol .cs-pro{
    text-align: unset;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .cs-pro p {
    /* font-size: 20px  !important; */
}
.header.my_profile_header .user-record .user-edit h3 {
  font-size: 26px;

}
  .profPageHeadRightCol .cs-pro p{
    /* margin-left: 15px; */
  }
  .package-info-div{
    justify-content: center;
    flex-direction: column;
  }
  .package-info-div img{
    max-width: 100%;
    margin-bottom: 20px !important;
  }
  .subscription .col-lg-8.my_subs_div_1{
    flex-direction: column;
  }
  .history-edit tr th, table.history-edit tr td{
    min-width: 80px;
  }
  .header.my_profile_header .user-record .user-edit h3{
    font-size: 26px;
    line-height: normal;
  }
  .user-edit span.HEADING{
   
    font-size: 18px;

  }
  .cancelSubscription_modal .modal-title{
    font-size: 22px;
  }
  .cancelSubscription_modal img {
    width: 70px;
  }
  .cancelSubscription_modal p {
    font-size: 16px;
    margin: 25px 0px 40px;
    line-height: 20px;
  }

  .package-info-div.profile a {
    width: 100%;
  }
  

}


@media (max-width: 500px){
  .change_pass_component.sec-3 .change-password input::placeholder{
    font-size: 12px;
  }
  button.btn.us-active-btn {
    width: 180px;
    font-size: 12px;
    height: auto;
}
  .col-lg-8.my_subs_div_1 {
    display: block;
    padding: 0;
    width: 100%;
  }
  .my_readbook_sec2 button.btn.us-active-btn{
    width: 50% !important;
    display: block;
    margin: 10px auto !important;
    max-width: 200px;
    padding: 7px;
    margin-top: 10px;
  }
  .profile-button button {
    font-size: 13px;
    padding: 8px 15px;
  }
  .cs-pro.membership p {
    font-size: 14px !important;
  }
  .user-acc-circle-dropdown.dashboard-header {
    width: 100px !important;
    height: 100px !important;
  }
  .cs-pro.membership .user-membership {
    padding: 6px 15px;
    font-size: 12px;
  }
  .cs-pro.membership p{
    margin-left:0 !important;
    /* font-size:12px !important; */
    font-size:15px !important;
  }
}


/* @media (max-width: 500px) {
  .history .table thead {
    display: none;
  }

  .history .table,
  .history .table tbody,
  .history .table tr,
  .history .table td {
    display: block;
    width: 100%;
  }
  .history .table tr {
    margin-bottom: 15px;
  }
  .history .table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
  }
  .history .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
} */
@media(max-width:420px){
  .free-book-details {
    width: calc(100% - 130px);
    padding-left: 15px;
  }
  .my_prof_div .free-book-image {
    width: 100%;
    max-width: 130px;
    height: 180px;
  }
  .my_prof_div .free-book-image img{
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .my_prof_div .timer-and-free-book-details {
    padding: 0px 20px;
    margin: 20px 0;
}
.my_prof_div  .timer-div {
  margin-bottom: 10px;
  top: -10px;
}
.my_prof_div .free-chapters-div .read-p {
  background: linear-gradient(45deg, #3455ee, #1b33d3);
  margin: 0;
  padding: 10px 12px;
  font-family: 'Montserrat-Light';
}
.my_prof_div .free-pkg .read-div {
  margin-top: 14px !important;
}
.my_prof_div .gradient-border {
  overflow: unset;
}
.my_prof_div .grounded-radiants {
  box-shadow: 0px 6px 10px #0000001a;
}

}


@media (max-width: 375px){
  .profHeadUserDet.header.my_profile_header .user-record .user-edit{
    text-align: left;
  }
  span.span-star{
    padding: 6px 15px;
  }
  .profPageHeadRightCol .cs-pro p{
    margin-left: 10px;
  }
  .profPageHeadRightCol .cs-pro p{
    font-size: 11px;
  }
  .header.my_profile_header .user-record .user-edit .email-record h6{
    font-size: 13px;
    margin: 3px 0px;
  }
  ul.navbar-nav.ml-auto.header-bar.my_prof_nav li.nav-item a{
    font-size: 10px;
  }
  .history-edit tr th, table.history-edit tr td{
    font-size: 12px;
  }
  table.history-edit tr td{
    font-size: 10px;
  }
  .history-edit tr th, table.history-edit tr td {
    padding: 7px 2px 7px 5px;
    line-height: normal;
  }
  .invoicebtn{
    width: 100%;
    margin-bottom: 0;
    height: 50px;
    font-size: 14px;
    padding: 10px;
  }
  .history-edit tr th, table.history-edit tr td {
    min-width: 70px;
  }
  .history-edit tr th.historyTab_Date {
    min-width: 80px;
  }
  .my_prof_div .free-book-image {
    /* min-width: 90px; */
    min-width: fit-content;
    max-width: 100%;
    height: 140px;
  }
  .free-pkg .freebook-subtitle {
    font-size: 13px;
    line-height: 18px;
  }

}

@media (max-width: 320px){
  .history-edit .pagination{
    justify-content: start;
    padding-left: 15%;
  }
}

.enabletxt {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.accounts-features .row.feature-border{
  align-items: center;
}