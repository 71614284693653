.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}
/***************** MOST POPULAR BOOK HEADER ******************/
.mp-books-header {
  display: flex;
  align-content: center;
  align-items: center;
}

p.mp-books-header-title {
  border-left: solid;
  padding-left: 14px;
  font-family: montserrat;
  text-transform: uppercase;
  font-size: 37px;
  font-weight: 600;
  border-color: #ffc240;
  border-width: 4px;
  height: 45px;
  display: flex;
  align-items: center;
}

p.mp-books-view-all {
  margin-left: auto;
  margin-right: 20px;
  font-family: montserrat;
  color: #757575;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  background: #ebebeb;
  padding: 10px 18px 10px 18px;
  border-radius: 333px;
  align-items: center;
}
p.mp-books-view-all:hover {
  cursor: pointer;
  background: #c3c3c3;
  color: #555555;
}
/***************** MOST POPULAR BOOK ROW ******************/
.mp-books-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  max-width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 auto;
}
/***************** MOST POPULAR BOOK CARD *****************/
.most-popular-books {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 15px 0 20px;
  max-width: 33.33%;
}
/*fixed*/
.most-popular-books-half {
  min-width: 45%;
  max-width: 45%;
}
.most-popular-books-full {
  min-width: 100%;
  max-width: 100%;
}
/******** MP COVER IMAGE ********/

.og-book-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
img.mp-book-cover {
  height: 166px;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 10px !important;
  transition: .5s all ease;
  object-fit: contain;
}

.og-book-image:hover {
  cursor: pointer;
  transform: scale(1.04175);
  transition-timing-function: ease-in;
  transition: all 0.5s;
}
/************ MP BOOK DETAILS ************/
.book-details {
  padding-left: 12px;
  max-width: 65%;
}
/******** MP TITLE ********/
.mp-book-title {
  font-family: "Montserrat-Bold";
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  max-height: 44px;
  text-transform: capitalize;
  color: #444444;

  /*if line greater 3 then ...*/
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /*if line greater 3 then ...*/

  margin: 0;
  margin-top: 3px;
}
p.mp-book-title:hover {
  /* text-decoration: underline;*/
  cursor: default; 
}
/******** MP CATEGORY ********/
.mp-book-category {
  display: flex;
  max-height: 36px;
  align-items: initial;
  align-items: center;
  /* margin-top: 4px; */
}
img.mp-book-cat-icon {
  width: 18px;
}

p.mp-book-category-tag {
  font-family: montserrat;
  text-transform: capitalize;
  padding-left: 4px;
  font-weight: 400;
  color: #8d939d;
  font-size: 15px;
  line-height: 15px;
  margin: 6px 0;
}

p.mp-book-status {
  /* margin-left: 0; */
  font-family: "montserrat";
  text-transform: capitalize;
  padding-left: 4px;
  font-weight: 400;
  color: #8d939d;
  font-size: 15px;
  line-height: 15px;
  /* margin: auto; */
}

/******** MP DESCRIPTION ********/
p.mp-book-description {
  font-family: "Montserrat";
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  text-transform: capitalize;
  color: #454545;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0;
  padding: 3px 0 2px 0;
  width: 100%;
}
/******** MP CHAPTER COUNT ********/
.mp-book-chapters {
  display: flex;
  margin: 0;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  align-items: center;
}

img.mp-book-chapter-icon {
  width: 12px;
}

.recentlyReadSecDashboard p.mp-book-chapter-number{
  display: none;
}
.recentlyReadSecDashboard .og-book-title{
  -webkit-line-clamp: 2;
}

p.mp-book-chapter-number {
  padding-left: 4px;
  font-family: montserrat;
  font-weight: 500;
  font-size: 17px;
  color: #333333;
  margin: 0;
}

p.mp-book-chapter-number.BookmarkChpNo {
  display: block;
  text-align: center;
  width: 100%;
  background-color: #fec342;
  padding: 5px;
  border: 1px solid #fec342;
  border-radius: 30px;
  color: #333333;
}

svg.mp-favorite-heart-unfilled {
  transform: scale(0.8);
  margin-left: auto;
  fill: #8d939d;
}

svg.mp-favorite-heart-unfilled:hover {
  fill: #f70000;
  cursor: pointer;
}

svg.mp-favorite-heart-unfilled:active {
  fill: #f70000;
}
/* Favorites Heart source link - https://iconmonstr.com/heart-thin-svg/ */

@media screen and (max-width: 1440px) {
  p.mp-books-header-title {
    font-size: 30px;
  }

  .mp-book-title {
    font-size: 16px;
  }

  p.mp-book-description {
    font-size: 13px;
  }

  p.mp-book-chapter-number {
    font-size: 13px;
  }
  svg.mp-favorite-heart2,
  svg.mp-favorite-heart {
    transform: scale(0.6);
    width: 20px;
    height: 20px;
  }

  p.mp-books-view-all {
    font-size: 13px;
  }
}

@media screen and (max-width: 1279px) {
  p.mp-books-header-title {
    font-size: 28px;
  }

  .mp-book-title {
    font-size: 15px;
    line-height: 18px;
  }
  /* p.mp-book-title.mostPopBookTitle{
    height: auto;
    padding-bottom: 5px;
    -webkit-line-clamp: 1;
  } */
  .mp-book-chapters{
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }

  p.mp-book-description {
    font-size: 12px;
    -webkit-line-clamp: 2;
  }

  p.mp-book-chapter-number {
    font-size: 11px;
  }

  .most-popular-books {
    max-width: 33%;
  }

  p.mp-books-view-all {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  p.mp-books-vil {
    margin-right: 0;
    padding: 5px 13px !important;
    font-size: 10px;
  }
}

@media screen and (max-width: 700px){
  .completed-books{
    max-width: 29%;
  }
  .completed-book-title{
    margin-bottom: 5px;
    -webkit-line-clamp: 1;
  }
}
@media screen and (max-width: 560px){
  .completed-books{
    max-width: 35%;
  }
  .completed-book-title{
    margin-bottom: 5px;
    -webkit-line-clamp: 1;
  }
}

@media screen and (max-width: 467px) {
  .book-details .mp-book-category p.mp-book-category-tag{
    margin-left: 20px !important;
    font-size: 14px;
    padding-left: 4px;
  }
}


@media screen and (max-width: 350px) {
  .book-details .mp-book-category p.mp-book-category-tag{
    margin-left: 5px !important;
    font-size: 14px;
    padding-left: 2px;
  }
  .most-popular-books .book-details {
    padding-left: 8px;
  }
  .recent-width .top10Books{
    width: 100px;
  }
  .recent-books-container .top10-book-title{
    -webkit-line-clamp: 1;
  }
  .top10-book-rating{
    margin-top: 2px;
    font-size: 15px;
  }
  .completed-books{
    max-width: 50%;
  }
  .completed-book-title{
    margin-bottom: 5px;
    -webkit-line-clamp: 1;
  }
}
