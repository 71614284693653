/* .site-footer {
  background: rgb(77, 74, 74);
  background: linear-gradient(
    145deg,
    rgba(77, 74, 74, 1) 0%,
    rgba(62, 60, 60, 1) 25%,
    rgba(0, 0, 0, 1) 100%
  ); 
  height: 200px;
   width : 100%;  
} */

.footer-parent {
  background: linear-gradient(
    to bottom right,
    rgba(77, 74, 74, 1) -18%,
    rgba(62, 60, 60, 1) 5%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 90px 0 40px 0 !important;
}

.footer-1st-col {
  height: 180px;
  display: flex;
  /* background-color: tomato; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.copyrights-and-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.footer-logo {
  width: 250px;
  object-fit: contain;
}
.social-icons-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social-icon {
  color: #5e5e5d;
  font-size: 37px;
  margin-right: 10px;
}
.copyright-cs {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 17px;
  color: rgb(148, 148, 148);
  font-family: "Montserrat-medium";
}

.privacy-terms {
  margin: 0;
  font-size: 12px;
  color: rgb(148, 148, 148);
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Montserrat-Medium";
}

.links-container {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-left: 50px; */
}

.links-heading {
  font-weight: 800;
  color: rgb(148, 148, 148);
  font-size: 33px;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.9px;
  text-transform: uppercase;
  margin: 0 0 30px 0;
}

.link-item {
  color: rgb(148, 148, 148);

  font-size: 30px;
  text-transform: capitalize;
  font-family: "Montserrat-Medium";
}

.link-item:hover {
  text-decoration: none;
  color: rgb(148, 148, 148);
}
.mobContactMenu{
  display: none;
}
p.copyright-cs a {
  color: rgb(148, 148, 148);
}
p.privacy-terms a {
  color: rgb(148, 148, 148);
}

@media (max-width: 1860px) {
.link-item {
  font-size: 25px;

}
}
@media (max-width: 1660px) {
  .links-heading {
    font-size: 22px;
  }

  .link-item {
    font-size: 20px;
    transition: 0.5s all ease;
  }

  .copyright-cs {
    font-size: 15px;
  }

  .social-icon {
    font-size: 32px;
  }
}

@media (max-width: 1366px) {
  .links-heading {
    font-size: 22px;
  }

  .link-item {
    font-size: 18px;
  }

  .footer-1st-col .copyrights-and-social {
    margin-top: 0px;
  }
  .footer-1st-col{
    height: 160px;
  }
  .footer-1st-col .svg-inline--fa.fa-w-14{
    width: 20px;
  }
  .footer-1st-col .svg-inline--fa.fa-w-20 {
    width: 33px;
  }

}

@media (max-width: 1279px) {
  .links-heading {
    font-size: 17px;
  }

  .link-item {
    font-size: 14px;
  }

  .copyright-cs {
    font-size: 13px;
  }

  .privacy-terms {
    font-size: 11px;
  }

  .footer-logo {
    width: 180px;
  }

  .social-icon {
    font-size: 24px;
  }

  .link-item {
    font-size: 14px;
  }
  .social-icon {
    font-size: 32px;
  }
  .copyright-cs {
    font-size: 12px;
  }
  .privacy-terms {
    font-size: 9px;
  }
  .footer-parent .col-lg-2 {
    max-width: 19.666667%;
  }
  .footer-1st-col{
    height: 140px;
  }
  .copyrights-and-social{
    margin-top: 30px;
  }
}

@media (max-width: 991px){
  .copyright-cs {
    font-size: 8px;
  }
  .privacy-terms {
    font-size: 7px;
  }
}

@media (max-width: 768px) {
  .link-item {
    font-size: 12px;
  }
  .social-icon {
    font-size: 28px;
  }
  .copyright-cs {
    font-size: 8px;
    text-align: center;
  }
  .footer-1st-col {
    height: 120px;
  }
  .privacy-terms {
    font-size: 7px;
    line-height: normal;
  }
  .links-heading {
    font-size: 17px;
  }
  .footer-logo {
    width: 180px;
  }
  .footer-parent {
    height: 240px !important;
    padding-top: 2rem !important;
  }
  .recent-update-table {
    margin-bottom: 4rem;
  }
}

@media (max-width: 767px) {
  .link-item {
    display: none;

    font-size: 16px;
  }
  .links-heading {
    font-size: 19px;
    display: none;
  }
  .footer-parent .ml-5 > .row .col-lg-3:not(:first-child) {
    display: none;
  }

  .footer-parent .ml-5 > .row {
      justify-content: center;
  }
  .footer-parent .ml-5 > .row .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer-logo {
    max-width: 280px;
  }
  .social-icon {
    font-size: 28px;
  }
  .copyright-cs {
    font-size: 15px;
  }
  .privacy-terms {
    font-size: 10px;
  }
  .footer-1st-col {
    height: 156px;
  }
  .footer-parent {
    border-radius: 25px 25px 0px 0px;
  }
  
  .mobContactMenu{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    width: 100%;
  }
  .mobContactMenu .link-item{
    display: inline-block;
    color: #949494;
    text-transform: uppercase;
    font-family: "Montserrat";
  }
  .mobContactMenu .link-item:first-child{
    margin-right: 20px;
  }
  .mobContactMenu .link-item:last-child{
    display: none;
  }
  .copyrights-and-social {
    margin-top: 0;
  }
  .copyright-cs {
    font-size: 12px;
  }

  .privacy-terms {
    font-size: 9px;
    margin-top: 5px;
  }

  .footer-1st-col .svg-inline--fa.fa-w-14 {
    width: 25px;
  }
  .footer-1st-col .svg-inline--fa.fa-w-20 {
    width: 40px;
  }

}

@media (max-width: 350px){
  .copyright-cs {
    font-size: 11px;
  }
  .footer-1st-col .link-item{
    font-size: 13px;
  }
}


@media (max-width: 320px){
  .copyright-cs {
    font-size: 9px;
  }
  .footer-1st-col .link-item{
    font-size: 11px;
  }
  .privacy-terms {
    font-size: 7px;
    margin-top: 0px;
  }
  .footer-parent .ml-5 > .row .col-lg-3{
    padding-left: 0;
    padding-right: 0;
  }
  .mobContactMenu .link-item:first-child {
    margin-right: 12px;
  }
  
}